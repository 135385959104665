//import utils from "../utils/utils";
import { configService } from './config.service.js';

const { prefixUrl } = await configService.appConfig;

const checkUserSession = async () => {
  let response;
  // store the original url in the session storage
  let url;
  if (window.location.pathname !== '/') {
    url = window.location.pathname;
  } else {
    url = '/';
  }
  sessionStorage.setItem('original-url', url);

  // Get the auth status from the server
  response = await fetch(`${prefixUrl}/users/me`, { credentials: 'include' });

  const responseBody = await response.json();

  // If the user was successfully authenticated in the server, get the system config and set the auth state
  // (note that at this point the cookie is already set)
  if (responseBody.userInfo) {
    return {
      status: 'Authenticated',
      name:
        responseBody.userInfo.name || responseBody.userInfo.preferred_username,
      logout: () => securityService.logoutUser(),
    };
  } else {
    return {
      status: 'Unauthenticated',
    };
  }
};

/**
 * Posts a request to initiate the authorisation of a user session.
 * Obtains the authServer's login url from the app server and redirect the user to authenticate in the auth server.
 */
const inititateUserAuth = async () => {
  const response = await fetch(`${prefixUrl}/login`, {
    method: 'POST',
    credentials: 'include',
  });
  const responseBody = await response.json();
  const loginUrl = responseBody.authLocation;
  if (loginUrl) {
    return loginUrl;
  } else {
    throw new Error('Invalid redirection');
  }
};

/**
 * To be used when there is a callback from the auth server - completes the authorisation of the session
 * If successful, returns the user info and the system configuration
 * Otherwise, just returns Unauthenticated
 */
const completeUserAuth = async () => {
  let response;
  let auth = {
    status: 'Unauthenticated',
  };

  //const searchParams = utils.getURLParams();
  let searchParamsStr;
  const url = window.location.href.toString();
  const separatorChar = url.indexOf('#') >= 0 ? '#' : '?';
  if (url.includes(separatorChar)) {
    searchParamsStr = url.substring(url.indexOf(separatorChar));
  }
  response = await fetch(`${prefixUrl}/login${searchParamsStr}`, {
    method: 'POST',
    credentials: 'include',
  });

  const responseBody = await response.json();

  // If the user was successfully authenticated in the server, get the system config and set the auth state
  // (note that at this point the cookie is already set)
  if (responseBody.userInfo) {
    // const systemConfigResponse = await configurationSvc.getSystemConfiguration();

    auth = {
      status: 'Authenticated',
      name:
        responseBody.userInfo.name || responseBody.userInfo.preferred_username,
      // systemConfig: systemConfigResponse.body,
      logout: () => securityService.logoutUser(),
    };

    // Restore the original url from the session storage (if any)
    const storedUrl = sessionStorage.getItem('original-url');
    sessionStorage.removeItem('original-url');
    if (storedUrl != null) {
      window.history.replaceState(null, '', storedUrl);
    }
  } else {
    auth = {
      status: 'Unauthenticated',
    };
  }

  return auth;
};

/**
 * Terminates the user's session and redirects to the login page
 */
const logoutUser = async () => {
  const response = await fetch(`${prefixUrl}/logout`, {
    credentials: 'include',
  });
  const data = await response.json();
  window.location.href = data.redirectUrl;
};

const securityService = {
  checkUserSession,
  inititateUserAuth,
  completeUserAuth,
  logoutUser,
};

export default securityService;
