import React from 'react';

const ComingSoon = () => {
  return (
    <div style={{ color: 'grey' }}>
      <h2>Coming Soon...</h2>
    </div>
  );
};

export default ComingSoon;
