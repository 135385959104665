import { configureStore } from '@reduxjs/toolkit';

import quizzesSlice from '../reducers/quizzes.js';

export default () => {
  const store = configureStore({
    reducer: {
      quizzes: quizzesSlice.reducer
    }
  });
  return store;
}

