import { RESOURCENOTFOUND, FIELDMISSING } from '../utils/errors.js';
import { configService } from './config.service.js';
const { prefixUrl } = await configService.appConfig;

async function getQuizzes() {
  const res = await fetch(`${prefixUrl}/quizzes`, {
    method: 'GET',
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Unable to fetch quizzes. Please try after some time.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }
  const responseBody = await res.json();
  return responseBody;
}

async function createQuiz(quiz) {
  const res = await fetch(`${prefixUrl}/quizzes`, {
    method: 'POST',
    body: JSON.stringify(quiz),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === FIELDMISSING) {
          throw new Error(
            'A required field is missing. Please contact administrator.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return responseBody;
}

async function getQuizDetails(quizId) {
  const res = await fetch(`${prefixUrl}/quizzes/${quizId}`, {
    method: 'GET',
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error('The quiz you requested does not exist.');
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return responseBody;
}

async function updateQuiz(quizId, data) {
  const res = await fetch(`${prefixUrl}/quizzes/${quizId}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to update quiz. The quiz you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const quiz = await res.json();
  return { quiz: { ...quiz } };
}

async function deleteQuiz(quizId) {
  const res = await fetch(`${prefixUrl}/quizzes/${quizId}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to delete quiz. The quiz you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  return { quizId };
}

async function publishQuiz(quizId, status) {
  const res = await fetch(`${prefixUrl}/quizzes/${quizId}/publish`, {
    method: 'POST',
    body: JSON.stringify({ status }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to publish quiz. The quiz you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const data = await res.json();
  return { quiz: { ...data } };
}

async function addQuestion(quizId, question) {
  const res = await fetch(`${prefixUrl}/quizzes/${quizId}/questions`, {
    method: 'POST',
    body: JSON.stringify(question),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to add question. The quiz you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const data = await res.json();
  return { quizId, question: { ...data } };
}

async function updateQuestion(quizId, question) {
  const res = await fetch(
    `${prefixUrl}/quizzes/${quizId}/questions/${question.questionId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(question),
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
  );

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to update question. The quiz you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const data = await res.json();
  return { quizId, question: { ...data } };
}

async function removeQuestion(quizId, questionId) {
  const res = await fetch(
    `${prefixUrl}/quizzes/${quizId}/questions/${questionId}`,
    {
      method: 'DELETE',
      credentials: 'include',
    }
  );

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to remove question. The quiz you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  return { quizId, questionId };
}

async function getSubmissions(quizId) {
  const res = await fetch(`${prefixUrl}/quizzes/${quizId}/submissions`, {
    method: 'GET',
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error('The quiz you requested does not exist.');
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const data = await res.json();
  return { ...data };
}

const quizService = {
  getQuizzes,
  createQuiz,
  getQuizDetails,
  updateQuiz,
  deleteQuiz,
  publishQuiz,
  addQuestion,
  updateQuestion,
  removeQuestion,
  getSubmissions,
};

export default quizService;
