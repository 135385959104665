import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments,
  faHashtag,
  faHome,
  faQuestion,
  faSitemap,
  faSquarePollVertical,
} from '@fortawesome/free-solid-svg-icons';

const NavItems = () => {
  const location = useLocation();
  return (
    <>
      <Link
        className={
          location.pathname === '/'
            ? 'nav-pane__link nav-pane__link-active'
            : 'nav-pane__link'
        }
        to="/"
      >
        <FontAwesomeIcon className="font-awesome__container" icon={faHome} />
        Home
      </Link>
      <Link
        className={
          location.pathname.includes('/quizzes')
            ? 'nav-pane__link nav-pane__link-active'
            : 'nav-pane__link'
        }
        to="/quizzes"
      >
        <FontAwesomeIcon
          className="font-awesome__container"
          icon={faQuestion}
        />
        Quizzes
      </Link>
      <Link
        className={
          location.pathname === '/crosswords'
            ? 'nav-pane__link nav-pane__link-active'
            : 'nav-pane__link'
        }
        to="/crosswords"
      >
        <FontAwesomeIcon className="font-awesome__container" icon={faHashtag} />
        Crosswords
      </Link>
      <Link
        className={
          location.pathname === '/surveys'
            ? 'nav-pane__link nav-pane__link-active'
            : 'nav-pane__link'
        }
        to="/surveys"
      >
        <FontAwesomeIcon
          className="font-awesome__container"
          icon={faSquarePollVertical}
        />
        Surveys
      </Link>
      <Link
        className={
          location.pathname === '/interactive-quizzes'
            ? 'nav-pane__link nav-pane__link-active'
            : 'nav-pane__link'
        }
        to="/interactive-quizzes"
      >
        <FontAwesomeIcon
          className="font-awesome__container"
          icon={faComments}
        />
        Interactive Quizzes
      </Link>
      <Link
        className={
          location.pathname === '/organisations'
            ? 'nav-pane__link nav-pane__link-active'
            : 'nav-pane__link'
        }
        to="/organisations"
      >
        <FontAwesomeIcon className="font-awesome__container" icon={faSitemap} />
        Organisations
      </Link>
    </>
  );
};

export default NavItems;
