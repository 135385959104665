import React from 'react';

const QuizShare = ({ id, link, copied, onCopy }) => {
  return (
    <div className="quiz-share">
      <input
        id={`link-${id}`}
        type="text"
        onFocus={(e) => {
          e.target.select();
        }}
        onChange={() => {}}
        value={link}
      />
      <button
        onClick={(e) => {
          const input = document.getElementById(`link-${id}`);
          navigator.clipboard.writeText(input.value);
          onCopy();
        }}
      >
        {copied ? 'COPIED' : 'COPY'}
      </button>
    </div>
  );
};

export default QuizShare;
