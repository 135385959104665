import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { clearError } from '../../redux/actions/quizzes.js';

const NotificationToast = (props) => {
  const [move, setMove] = useState('');

  useEffect(() => {
    if (props.error) {
      setMove('dropdown');
      setTimeout(() => {
        setMove('riseup');
        props.clearError();
      }, 5000);
    }
  }, [props.error]);

  return (
    <div className={`notification-toast__container ${move}`}>
      <div className={`notification-toast__content`}>
        <div className="notification-toast__message">
          {props.errorDetail}
          <button className={`notification-toast__close`}>x</button>
        </div>
        <div className="notification-toast__timer"></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.quizzes.error,
    errorDetail: state.quizzes.errorDetail,
  };
};

const mapDispatchToProps = {
  clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationToast);
