import React, { useRef } from 'react';

const Modal = ({ id, component }) => {
  const ref = useRef();

  const closeModal = (e) => {
    const modal = document.getElementById(id);
    if (e) {
      if (modal && !ref.current?.contains(e.target)) {
        modal.style.display = 'none';
      }
    }
  };

  document.addEventListener('mousedown', closeModal);
  return (
    <div id={id} className="modal-container">
      <div ref={ref} className="modal-content">
        {component}
      </div>
    </div>
  );
};

export default Modal;
