import { createSlice } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

import {
  createQuiz,
  getQuizzes,
  updateQuiz,
  addQuestion,
  updateQuestion,
  removeQuestion,
  deleteQuiz,
  publishQuiz,
  getQuiz,
  getSubmissions,
} from '../actions/quizzes.js';

const quizzesSlice = createSlice({
  name: 'quizzes',
  initialState: {
    quizzes: [],
    loading: false,
    error: false,
    errorDetail: '',
    lastCreatedQuizId: null,
    editingQuestionId: null,
  },
  reducers: {
    setEditingQuestionId: (state, action) => {
      state.editingQuestionId = action.payload.questionId || null;
    },
    setlastCreatedQuizId: (state, action) => {
      state.lastCreatedQuizId = action.payload.quizId || null;
    },
    clearError: (state, _action) => {
      state.error = false;
      state.errorDetail = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuizzes.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getQuizzes.fulfilled, (state, action) => {
        state.quizzes = [...action.payload.quizzes];
        state.loading = false;
        state.error = false;
      })
      .addCase(getQuizzes.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(createQuiz.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createQuiz.fulfilled, (state, action) => {
        const {
          quizId = v4(),
          name = '',
          duration,
          status,
          questionsCount = 0,
          questions = [],
        } = action.payload.quiz;
        const newQuiz = {
          quizId,
          name,
          duration,
          status,
          questions,
          questionsCount,
        };
        state.quizzes = [...state.quizzes, newQuiz];
        state.loading = false;
        state.error = false;
        state.lastCreatedQuizId = newQuiz.quizId;
      })
      .addCase(createQuiz.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(updateQuiz.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateQuiz.fulfilled, (state, action) => {
        const { quizId, name, duration } = action.payload.quiz;
        const quiz = state.quizzes.find((quiz) => quiz.quizId === quizId);
        quiz.name = name;
        quiz.duration = duration;
        state.loading = false;
        state.error = false;
      })
      .addCase(updateQuiz.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(getQuiz.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getQuiz.fulfilled, (state, action) => {
        const { quizId, questions } = action.payload.quiz;
        const quiz = state.quizzes.find((quiz) => quiz.quizId === quizId);
        quiz.questions = questions;
        state.loading = false;
        state.error = false;
      })
      .addCase(getQuiz.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(publishQuiz.pending, (state, action) => {
        const { quizId } = action.meta.arg;
        const quiz = state.quizzes.find((quiz) => quiz.quizId === quizId);
        quiz.loading = true;
        quiz.error = false;
      })
      .addCase(publishQuiz.fulfilled, (state, action) => {
        const { quizId, status, code } = action.payload.quiz;
        const quiz = state.quizzes.find((quiz) => quiz.quizId === quizId);
        quiz.status = status;
        quiz.code = code;
        quiz.loading = false;
        quiz.error = false;
      })
      .addCase(publishQuiz.rejected, (state, action) => {
        quiz.loading = false;
        quiz.error = true;
        quiz.errorDetail = action.payload;
      })
      .addCase(deleteQuiz.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(deleteQuiz.fulfilled, (state, action) => {
        const { quizId } = action.payload;
        state.quizzes = state.quizzes.filter((quiz) => quiz.quizId !== quizId);
        state.loading = false;
        state.error = false;
      })
      .addCase(deleteQuiz.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(addQuestion.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        const { question, quizId } = action.payload;
        const quiz = state.quizzes.find((quiz) => quiz.quizId === quizId);
        quiz.questions.push(question);
        quiz.questionsCount += 1;
        state.loading = false;
        state.error = false;
      })
      .addCase(addQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(updateQuestion.pending, (state, _action) => {
        const { quizId, question } = _action.meta.arg;
        const quiz = state.quizzes.find((quiz) => quiz.quizId === quizId);
        const que = quiz.questions.find(
          (q) => q.questionId === question.questionId
        );
        que.loading = true;
        state.error = false;
      })
      .addCase(updateQuestion.fulfilled, (state, action) => {
        const { quizId, question } = action.payload;
        const quiz = state.quizzes.find((quiz) => quiz.quizId === quizId);
        const updatedQuestions = quiz.questions.map((ques) =>
          ques.questionId === question.questionId
            ? { ...ques, ...question }
            : ques
        );
        quiz.questions = updatedQuestions;
        const que = quiz.questions.find(
          (q) => q.questionId === question.questionId
        );
        que.loading = false;
        state.error = false;
        state.editingQuestionId = null;
      })
      .addCase(updateQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(removeQuestion.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(removeQuestion.fulfilled, (state, action) => {
        const { quizId, questionId } = action.payload;
        const quiz = state.quizzes.find((quiz) => quiz.quizId === quizId);
        quiz.questions = quiz.questions.filter(
          (q) => q.questionId !== questionId
        );
        quiz.questionsCount -= 1;
        state.quizzes.filter((quiz) => quiz.quizId !== quizId).concat([quiz]);
        state.loading = false;
        state.error = false;
        state.editingQuestionId = null;
      })
      .addCase(removeQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(getSubmissions.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSubmissions.fulfilled, (state, action) => {
        const { quizId, submissions } = action.payload;
        const quiz = state.quizzes.find((quiz) => quiz.quizId === quizId);
        quiz.submissions = submissions;
        state.loading = false;
        state.error = false;
      })
      .addCase(getSubmissions.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      });
  },
});

export default quizzesSlice;
