import React from 'react';

import NavItems from './NavItems.js';

const NavPane = () => {
  return (
    <div className="nav-pane">
      <NavItems />
    </div>
  );
};

export default NavPane;
