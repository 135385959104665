import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import QuizForm from '../../components/Quiz/QuizForm.js';
import {
  createQuiz,
  updateQuiz,
  addQuestion,
  updateQuestion,
  removeQuestion,
  setEditingQuestionId,
  setlastCreatedQuizId,
  deleteQuiz,
  clearError,
} from '../../redux/actions/quizzes.js';
import QuestionForm from '../../components/Question/QuestionForm.js';
import QuestionItem from '../../components/Question/QuestionItem.js';

const CreateQuiz = (props) => {
  let createdQuiz;
  useEffect(() => {
    return function cleanup() {
      props.setlastCreatedQuizId({ quizId: null });
      props.clearError();
    };
  }, []);
  const navigate = useNavigate();
  createdQuiz = props.createdQuiz;
  const emptyQuestion = {
    text: '',
    optionA: '',
    optionB: '',
    optionC: '',
    optionD: '',
    answer: 'A',
  };

  const [isEditing, setIsEditing] = useState(true);
  const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false);

  const handleEditCancel = () => {
    if (createdQuiz) {
      setIsEditing(false);
    } else {
      props.setlastCreatedQuizId({ quizId: null });
      navigate('/quizzes');
    }
  };

  const handleQuizDelete = () => {
    props.deleteQuiz(createdQuiz.quizId);
    navigate('/quizzes');
  };

  const handleAddQuestionItem = (question) => {
    props.addQuestion({ question, quizId: createdQuiz.quizId });
  };

  const handleEditingQuestionItem = (question) => {
    props.setEditingQuestionId({ questionId: question.questionId });
  };

  const handleCancelEditingQuestionItem = () => {
    props.setEditingQuestionId({ questionId: null });
  };

  const handleUpdateQuestionItem = (question) => {
    props.updateQuestion({ quizId: createdQuiz.quizId, question });
  };

  const handleremoveQuestionItem = (question) => {
    props.removeQuestion({
      quizId: createQuiz.quizId,
      questionId: question.questionId,
    });
  };

  return (
    <div className="quiz__container">
      {createdQuiz && !isEditing ? (
        <div className="quiz__header">
          <div className="quiz__header__heading">
            <h3>{createdQuiz.name}</h3>
            <p>{createdQuiz.duration / 60} min.</p>
          </div>
          <div className="quiz__header__actions">
            <button
              className="btn--primary"
              onClick={() => {
                setIsEditing(true);
              }}
            >
              EDIT
            </button>
            <button className="btn--secondary" onClick={handleQuizDelete}>
              DELETE
            </button>
          </div>
        </div>
      ) : (
        <div className="quiz__header">
          <QuizForm
            quiz={createdQuiz}
            onSubmit={(quiz) => {
              createdQuiz
                ? props.updateQuiz({
                    quizId: quiz.quizId,
                    data: { name: quiz.name, duration: quiz.duration },
                  })
                : props.createQuiz(quiz);
              setIsEditing(false);
            }}
            onCancel={handleEditCancel}
          />
        </div>
      )}
      {createdQuiz && (
        <>
          <button
            className="btn--primary"
            onClick={(e) => {
              const form = document.getElementById('create');
              if (isAddQuestionOpen) {
                form.style.display = 'none';
              } else {
                form.style.display = 'flex';
              }
              setIsAddQuestionOpen(!isAddQuestionOpen);
            }}
          >
            {isAddQuestionOpen ? 'CANCEL' : 'ADD QUESTION'}
          </button>
          <QuestionForm
            action="create"
            question={emptyQuestion}
            onAdd={handleAddQuestionItem}
          />
        </>
      )}
      {createdQuiz && createdQuiz.questions.length > 0 && (
        <div className="quiz__content">
          {createdQuiz.questions.map((question) =>
            question.questionId === props.editingQuestionId ? (
              <QuestionForm
                key={question.questionId}
                question={question}
                onAdd={handleUpdateQuestionItem}
                onCancel={handleCancelEditingQuestionItem}
              />
            ) : (
              <QuestionItem
                key={question.questionId}
                question={question}
                onEdit={handleEditingQuestionItem}
                onDelete={handleremoveQuestionItem}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const createdQuiz = state.quizzes.quizzes.find(
    (quiz) => quiz.quizId === state.quizzes.lastCreatedQuizId
  );
  return {
    quizzes: state.quizzes.quizzes,
    loading: state.quizzes.loading,
    error: state.quizzes.error,
    errorDetail: state.quizzes.errorDetail,
    createdQuiz,
    editingQuestionId: state.quizzes.editingQuestionId,
  };
};

const mapDispatchToProps = {
  createQuiz,
  updateQuiz,
  deleteQuiz,
  addQuestion,
  updateQuestion,
  removeQuestion,
  setEditingQuestionId,
  setlastCreatedQuizId,
  clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuiz);
