import React from 'react';
import { connect } from 'react-redux';

import QuizListItem from './QuizListItem.js';
import { publishQuiz, deleteQuiz } from '../../redux/actions/quizzes.js';
import QuizItemSkeleton from '../UI/QuizItemSkeleton.js';

const QuizList = (props) => {
  const skeletons = [1, 2, 3];
  const handleDeleteQuiz = (quizId) => {
    props.deleteQuiz(quizId);
  };

  const handlePublishQuiz = (quizId, status) => {
    props.publishQuiz({ quizId, status });
  };

  return props.error ? (
    <div>{props.errorDetail}</div>
  ) : props.loading ? (
    <div className="quiz-list__content">
      {skeletons.map((sk) => (
        <QuizItemSkeleton key={sk} quiz={{ status: 'draft' }} />
      ))}
    </div>
  ) : props.quizzes.length < 1 ? (
    <div
      style={{
        width: '70%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <p style={{ textAlign: 'center', color: 'grey' }}>
        Wow, such emptiness. Why don't you create a new quiz. :)
      </p>
    </div>
  ) : (
    <div className="quiz-list__content">
      {props.quizzes.map((quiz) => (
        <QuizListItem
          key={quiz.quizId}
          quiz={quiz}
          onDelete={handleDeleteQuiz}
          onPublish={handlePublishQuiz}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  quizzes: state.quizzes.quizzes,
  loading: state.quizzes.loading,
  error: state.quizzes.error,
  errorDetail: state.quizzes.errorDetail,
});

const mapDispatchToProps = {
  publishQuiz,
  deleteQuiz,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizList);
