import React, { useState } from 'react';
import { connect } from 'react-redux';

const QuestionForm = (props) => {
  const [question, setQuestion] = useState(props.question);
  const handleTextChange = (e) => {
    setQuestion({ ...question, text: e.target.value });
  };

  const handleOptionAChange = (e) => {
    setQuestion({ ...question, optionA: e.target.value });
  };

  const handleOptionBChange = (e) => {
    setQuestion({ ...question, optionB: e.target.value });
  };

  const handleOptionCChange = (e) => {
    setQuestion({ ...question, optionC: e.target.value });
  };

  const handleOptionDChange = (e) => {
    setQuestion({ ...question, optionD: e.target.value });
  };

  const handleAnswerChange = (e) => {
    setQuestion({ ...question, answer: e.target.value });
  };

  return (
    <div id={props.action} className="question-form__container">
      <div className="question-form__content">
        <div className="question-form__text">
          <textarea
            className="question-form__text-area"
            placeholder="Question"
            value={question.text}
            onChange={handleTextChange}
          />
        </div>
        <div className="question-form__options">
          <input
            className="question-form__option"
            type="text"
            placeholder="Option A"
            value={question.optionA}
            onChange={handleOptionAChange}
          />
          <input
            className="question-form__option"
            type="text"
            placeholder="Option B"
            value={question.optionB}
            onChange={handleOptionBChange}
          />
          <input
            className="question-form__option"
            type="text"
            placeholder="Option C"
            value={question.optionC}
            onChange={handleOptionCChange}
          />
          <input
            className="question-form__option"
            type="text"
            placeholder="Option D"
            value={question.optionD}
            onChange={handleOptionDChange}
          />
        </div>
        <div className="question-form__answer">
          <p>Correct Answer: </p>
          <select value={question.answer} onChange={handleAnswerChange}>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </select>
        </div>
        <div className="question-form__controls">
          <button
            className="btn--primary"
            onClick={() => {
              props.onAdd(question);
              setQuestion({
                ...props.question,
              });
            }}
          >
            {props.question.questionId ? 'UPDATE' : 'ADD'}
          </button>
          {props.action !== 'create' && (
            <button
              className="btn--secondary"
              onClick={() => {
                props.onCancel();
                setQuestion({ ...props.question });
              }}
            >
              CANCEL
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect()(QuestionForm);
