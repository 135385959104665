import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from '../pages/Home.js';
import ListQuizzes from '../pages/Quiz/ListQuizzes.js';
import CreateQuiz from '../pages/Quiz/CreateQuiz.js';
import EditQuiz from '../pages/Quiz/EditQuiz.js';
import Header from '../components/UI/Header.js';
import PageNotFound from '../pages/PageNotFound.js';
import NavPane from '../components/UI/NavPane.js';
import ListSubmissions from '../pages/Quiz/ListSubmissions.js';
import ComingSoon from '../pages/ComingSoon.js';
import NotificationToast from '../components/UI/NotificationToast.js';

const AppRouter = (params) => {
  return (
    <BrowserRouter>
      <div className="root-container">
        <NotificationToast />
        <div className="header-container">
          <Header auth={params.auth} />
        </div>
        <div className="main-container">
          <div className="nav-container">
            <NavPane />
          </div>
          <div className="app-container">
            <Routes>
              <Route path="/" Component={Home} />
              <Route path="/quizzes" Component={ListQuizzes} />
              <Route path="/quizzes/create" Component={CreateQuiz} />
              <Route path="/quizzes/:id" Component={EditQuiz} />
              <Route
                path="/quizzes/:id/submissions"
                Component={ListSubmissions}
              />
              <Route path="/crosswords" Component={ComingSoon} />
              <Route path="/surveys" Component={ComingSoon} />
              <Route path="/interactive-quizzes" Component={ComingSoon} />
              <Route path="/organisations" Component={ComingSoon} />
              <Route path="*" Component={PageNotFound} />
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default AppRouter;
