import React from 'react';
import { useState } from 'react';

const QuizForm = (props) => {
  const [quiz, setQuiz] = useState(
    props.quiz || {
      name: '',
      duration: 600,
    }
  );

  const handleNameChange = (e) => {
    setQuiz({
      ...quiz,
      name: e.target.value,
    });
  };

  const handleDurationChange = (e) => {
    setQuiz({
      ...quiz,
      duration: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit(quiz);
  };

  return (
    <form className="quiz-form" onSubmit={handleSubmit}>
      <div className="quiz-form__control">
        <input
          type="text"
          name="quizName"
          placeholder="Quiz Name"
          defaultValue={quiz.name}
          onChange={handleNameChange}
        />
        <select
          name="quizDuration"
          defaultValue={quiz.duration}
          onChange={handleDurationChange}
        >
          <option value={300}>5 min</option>
          <option value={600}>10 min</option>
          <option value={900}>15 min</option>
          <option value={1200}>20 min</option>
          <option value={1500}>25 min</option>
          <option value={1800}>30 min</option>
          <option value={2400}>40 min</option>
          <option value={3000}>50 min</option>
          <option value={3600}>60 min</option>
        </select>
      </div>
      <div className="quiz-form__actions">
        <button className="btn--primary" type="submit">
          {props.quiz ? 'UPDATE' : 'CREATE'}
        </button>
        <button className="btn--secondary" onClick={props.onCancel}>
          CANCEL
        </button>
      </div>
    </form>
  );
};

export default QuizForm;
