import React from 'react';

const QuizItemSkeleton = ({ quiz }) => {
  return (
    <div className="quiz-item">
      <div className="quiz-item__head">
        <div className="quiz-item__head__heading">
          <div className="h3__skeleton"></div>
          <div className="text__skeleton"></div>
          <div className="text__skeleton"></div>
        </div>
      </div>
      <div className="quiz-item__actions">
        <button className="btn__skeleton--primary"></button>
        <button
          className="btn__skeleton--secondary"
          disabled={quiz.status === 'draft'}
        ></button>
      </div>
    </div>
  );
};

export default QuizItemSkeleton;
