import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { getSubmissions } from '../../redux/actions/quizzes.js';

const ListSubmissions = (props) => {
  const params = useParams();
  const quizId = params.id;

  useEffect(() => {
    const quiz = props.quizzes.find((quiz) => quiz.quizId === quizId);
    if (!quiz.ListSubmissions) props.getSubmissions(quizId);
  }, []);
  const quiz = props.quizzes.find((quiz) => quiz.quizId === quizId);

  return (
    <div className="submissions-container">
      {/* <div className="submission--header">
        <p>Name</p>
        <p>Email</p>
        <p>Score</p>
      </div> */}
      {/* {quiz.submissions &&
        quiz.submissions.map((sub, i) => (
          <div
            key={i}
            className={i % 2 === 0 ? 'submission' : 'submission--alt'}
          >
            <div>{sub.name}</div>
            <div>{sub.email}</div>
            <div>{sub.score}</div>
          </div>
        ))} */}
      {quiz.submissions && (
        <>
          <div className="submission-column--name">
            <div className="submission--header">
              <p>Name</p>
            </div>
            {quiz.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.name}</p>
              </div>
            ))}
          </div>
          <div className="submission-column--email">
            <div className="submission--header">
              <p>Email</p>
            </div>
            {quiz.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.email}</p>
              </div>
            ))}
          </div>
          <div className="submission-column--score">
            <div className="submission--header">
              <p>Score</p>
            </div>
            {quiz.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.score}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  // const params = useParams();
  return {
    quizzes: state.quizzes.quizzes,
    loading: state.quizzes.loading,
    error: state.quizzes.error,
  };
};

const mapDispatchToProps = {
  getSubmissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListSubmissions);
