import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { clearError, getQuizzes } from '../../redux/actions/quizzes.js';
import QuizList from '../../components/Quiz/QuizList.js';

const ListQuizzes = (props) => {
  useEffect(() => {
    if (props.quizzes.length === 0) props.getQuizzes();
    return () => {
      props.clearError();
    };
  }, []);
  return <QuizList />;
};

const mapStateToProps = (state) => ({
  quizzes: state.quizzes.quizzes,
  loading: state.quizzes.loading,
  error: state.quizzes.error,
  errorDetail: state.quizzes.errorDetail,
});

const mapDispatchToProps = {
  getQuizzes,
  clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListQuizzes);
