import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div style={{ color: 'grey' }}>
      <h1>OOPS! How did you land here?</h1>
      <p>
        Let's get you back. Click{' '}
        <Link style={{ textDecoration: 'none' }} to="/">
          HERE
        </Link>{' '}
        to go to Home Page
      </p>
    </div>
  );
};

export default PageNotFound;
