import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxArchive,
  faCircleDown,
  faCircleUp,
  faClipboardCheck,
  faShareNodes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

import { configService } from '../../services/config.service.js';
import Modal from '../UI/Modal.js';
import QuizShare from './QuizShare.js';
import QuizItemSkeleton from '../UI/QuizItemSkeleton.js';

const { playServer } = await configService.appConfig;

const QuizListItem = (props) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const closeOpenMenus = (e) => {
    if (e) {
      if (isOpen && !ref.current?.contains(e.target)) {
        setIsOpen(false);
      }
    } else {
      if (isOpen) {
        setIsOpen(false);
      }
    }
  };

  const handleCodeCopy = () => {
    setCopied(true);
  };

  document.addEventListener('mousedown', closeOpenMenus);

  return props.quiz.loading ? (
    <QuizItemSkeleton
      quiz={props.quiz}
      quizStatus={props.quiz.status === 'published' ? 'draft' : 'published'}
    />
  ) : (
    <>
      {props.quiz.code && (
        <Modal
          id={props.quiz.code}
          component={
            <QuizShare
              id={props.quiz.code}
              link={`${playServer}?code=${props.quiz.code}`}
              copied={copied}
              onCopy={handleCodeCopy}
            />
          }
        />
      )}
      <Link className="quiz-item" to={`/quizzes/${props.quiz.quizId}`}>
        <div className="quiz-item__head">
          <div className="quiz-item__head__heading">
            <h3>{props.quiz.name}</h3>
            <p>{props.quiz.duration / 60} min.</p>
            <p>{props.quiz.questionsCount} question(s)</p>
          </div>
          <div ref={ref} className="quiz-item__head__options__container">
            <div
              className="quiz-item__options"
              onClick={(event) => {
                event.preventDefault();
                setIsOpen(!isOpen);
              }}
            >
              &#8942;
            </div>
            <div className={`quiz-item__options__menu ${isOpen ? 'show' : ''}`}>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  window.location.href = `/quizzes/${props.quiz.quizId}/submissions`;
                }}
              >
                <FontAwesomeIcon
                  className="font-awesome__container"
                  icon={faClipboardCheck}
                />
                View Submissions
              </button>
              <button
                disabled={props.quiz.status !== 'draft'}
                onClick={(event) => {
                  event.preventDefault();
                  props.onDelete(props.quiz.quizId);
                }}
              >
                <FontAwesomeIcon
                  className="font-awesome__container"
                  icon={faTrash}
                />
                Delete
              </button>
              <button
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                <FontAwesomeIcon
                  className="font-awesome__container"
                  icon={faBoxArchive}
                />
                Archive
              </button>
            </div>
          </div>
        </div>
        <div className="quiz-item__actions">
          <button
            className="btn--primary"
            onClick={(event) => {
              event.preventDefault();
              props.onPublish(
                props.quiz.quizId,
                props.quiz.status === 'draft' ? 'published' : 'draft'
              );
            }}
            disabled={props.quiz.questionsCount === 0}
          >
            {props.quiz.status === 'draft' ? (
              <>
                <FontAwesomeIcon
                  className="font-awesome__container"
                  icon={faCircleUp}
                />
                PUBLISH
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  className="font-awesome__container"
                  icon={faCircleDown}
                />
                UNPUBLISH
              </>
            )}
          </button>
          <button
            className="btn--secondary quiz-item__share"
            value={props.quiz.code}
            onClick={(event) => {
              event.preventDefault();
              const modal = document.getElementById(props.quiz.code);
              setCopied(false);
              modal.style.display = 'flex';
            }}
            disabled={props.quiz.status === 'draft'}
          >
            <FontAwesomeIcon
              className="font-awesome__container"
              icon={faShareNodes}
            />
            SHARE
          </button>
        </div>
      </Link>
    </>
  );
};

export default QuizListItem;
