import React, { useEffect, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faIdBadge,
  faMagnifyingGlass,
  faRightFromBracket,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';

import NavItems from './NavItems.js';

const Header = (params) => {
  const location = useLocation();
  const refAccount = useRef();
  const refNav = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const closeOpenMenus = (e) => {
    if (e) {
      if (isOpen && !refAccount.current?.contains(e.target)) {
        setIsOpen(false);
      }
      const menuToggle = document.getElementById('menu');
      if (menuToggle.checked && !refNav.current?.contains(e.target)) {
        menuToggle.checked = false;
      }
    } else {
      if (isOpen) {
        setIsOpen(false);
      }
    }
  };
  useEffect(() => {
    closeOpenMenus();
  }, [location]);
  document.addEventListener('mousedown', closeOpenMenus);
  return (
    <div className="header">
      <div className="header__nav">
        {/* <div className="header__nav__button"> */}
        <input
          className="header__nav__menu-toggle"
          id="menu"
          type="checkbox"
          defaultChecked={false}
        ></input>
        <label htmlFor="menu" className="header__nav__menu-container">
          <div className="header__nav__menu-button">
            <div className="hamburger__menu__bar"></div>
            <div className="hamburger__menu__bar"></div>
            <div className="hamburger__menu__bar"></div>
          </div>
        </label>
        <div className="header__nav__nav-pane" ref={refNav}>
          <button
            className="header__nav__nav-pane__close"
            onClick={() => {
              document.getElementById('menu').checked = false;
            }}
          >
            X
          </button>
          <NavItems />
        </div>
        {/* </div> */}
        <Link className="header__root-link" to="/">
          <div className="header__logo-g">G</div>&nbsp;
          <div className="header__logo-k">K</div>
          <div className="header__logo-gyaan">GYAAN</div>&nbsp;
          <div className="header__logo-kreeda">KREEDA</div>
        </Link>
      </div>
      <div className="header__rest">
        <div className="header__search__container">
          <FontAwesomeIcon
            className="font-awesome__search"
            icon={faMagnifyingGlass}
          />
          <input
            className="header__search"
            type="text"
            placeholder="Search quizzes"
          />
        </div>
        <button className="header__search--mobile">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </button>
        <Link className="header__create-button" to="/quizzes/create">
          Create
        </Link>
        <Link className="header__create-button--mobile" to="/quizzes/create">
          +
        </Link>
        <div className="header__account-section" ref={refAccount}>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="header__account-section__user--mobile"
          >
            {params.auth.name
              .split(' ')
              .map((n) => n[0])
              .join('')}
          </button>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="header__account-section__user"
          >
            {params.auth.name}
          </button>

          <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
            <Link to="/profile">
              <FontAwesomeIcon
                className="font-awesome__container"
                icon={faIdBadge}
              />
              Profile
            </Link>
            <Link to="/settings">
              <FontAwesomeIcon
                className="font-awesome__container"
                icon={faSliders}
              />
              Settings
            </Link>
            <button onClick={() => params.auth.logout()}>
              <FontAwesomeIcon
                className="font-awesome__container"
                icon={faRightFromBracket}
              />
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
