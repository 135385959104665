import React from 'react';

const QuestionItemSkeleton = () => {
  return (
    <>
      <div className="question-item__container">
        <div className="question-item__content">
          <div className="h4__skeleton"></div>
          <div className="question-item__options">
            <div className="text__skeleton"></div>
            <div className="text__skeleton"></div>
            <div className="text__skeleton"></div>
            <div className="text__skeleton"></div>
          </div>
          <div className="question-item__answer">
            <div className="text__skeleton"></div>
          </div>
        </div>
        <div className="question-item__controls">
          <button className="btn__skeleton--primary"></button>
          <button className="btn__skeleton--secondary"></button>
        </div>
      </div>
    </>
  );
};

export default QuestionItemSkeleton;
